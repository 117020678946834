header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 15px;
    width: 80vw;
    z-index: 1;
    scroll-snap-align: start;
  }
  
  header .text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  }
  
  header .text h1 {
    position: relative;
    display: flex;
    align-self: center;
    color: hsla(0, 0%, 100%, 0.89);
    font-family: Varela Round, sans-serif;
    opacity: 0;
    animation: show 1s ease 1s forwards;
  }
  
  @keyframes show {
    to {
      opacity: 1;
      transform: translate(0);
    }
  }
  
  header .text span {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: linear-gradient(45deg, var(--clr-pri), var(--clr-sec));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Varela Round, sans-serif;
    font-size: 40px;
    font-weight: 700;
    padding-right: 20px;
    height: 0;
    animation: animate-header 1s ease 1.9s forwards;
  }
  
  @keyframes animate-header {
    to {
      height: 48px;
    }
  }
  
  header .text span:before {
    position: absolute;
    content: "";
    border-radius: 100px;
    bottom: 0;
    height: 2px;
    width: 0;
    background: linear-gradient(50deg, var(--clr-pri), var(--clr-sec));
    animation: expand 1.1s ease 1.4s forwards;
  }
  
  @keyframes expand {
    to {
      width: 100%;
    }
  }
  
  header span:after {
    position: absolute;
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    right: 12px;
    top: 10px;
    background: linear-gradient(50deg, var(--clr-pri), var(--clr-sec));
    opacity: 0;
    animation: show 1s ease 3s forwards;
  }
  
  header h2 {
    color: hsla(0, 0%, 100%, 0.884);
    display: flex;
    font-family: Varela Round, sans-serif;
  }
  header h2 p {
    animation: jump 1s ease infinite;
    animation-delay: calc(var(--x) * 0.2s);
  }
  
  @keyframes jump {
    0% {
      transform: translateY(0);
    }
    50% {
      color: var(--clr-pri);
      transform: translateY(-10px);
    }
    70% {
      color: var(--clr-sec);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @media (max-width: 500px) {
    header {
      width: 100vw;
    }
    header .text h1 {
      font-size: 25px;
    }
    header .text span {
      font-size: 35px;
    }
  }
  