.section2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80vw;
  min-height: 100vh;
  padding: 0 15px;
  scroll-snap-align: start;
}

.section2 .text-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  width: 100%;
}

.section2 .text-content .text {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  align-items: center;
  justify-content: center;
}

.section2 .text h1 {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  opacity: 0;
  padding: 10px 0;
}

.section2.active h1 {
  animation: show 1s ease 0.5s forwards;
}

.section2 .text h1 span {
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  font-family: Varela Round, cursive;
  font-size: 40px;
  background: linear-gradient(50deg, var(--clr-pri), var(--clr-sec));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 0;
}

.section2 .text h1 span:before {
  background: linear-gradient(50deg, var(--clr-pri), var(--clr-sec));
  bottom: 0;
  content: "";
  height: 2px;
  position: absolute;
  width: 0;
}

.section2.active .text-content .text1 h1 span {
  animation: animate-started 1s ease 2s forwards;
}

@keyframes animate-started {
  to {
    height: 48px;
  }
}

.section2.active .text h1 span:before {
  animation: expand 1s ease 1.2s forwards;
}

.section2 .text-content .text1 h2 {
  border-left: 1px solid hsla(0, 0%, 100%, 0.575);
  color: #fff;
  font-size: 18px;
  font-weight: 100;
  margin-top: 5px;
  opacity: 0;
  padding: 20px 15px;
  position: relative;
}

.section2.active .text-content .text1 h2 {
  animation: show 1s ease 3s forwards;
}

.section2 .text-content .text2 h1 span {
  padding: 3px;
}

.section2.active .text-content .text2 h1 span {
  animation: animate-exp 1s ease 2s forwards;
}

@keyframes animate-exp {
  to {
    height: 54px;
  }
}
.section2 .text-content .text2 h1 span:before {
  animation-delay: 1.2s;
}

.section2 .text2 h2 {
  border-left: 1px solid hsla(0, 0%, 100%, 0.575);
  color: #fff;
  display: flex;
  font-size: 18px;
  font-weight: 100;
  margin-top: 5px;
  opacity: 0;
  padding: 20px 15px;
  position: relative;
}

.section2.active .text2 h2 {
  animation: show 1s ease 3s forwards;
}

.section2 .progress {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  margin-top: 150px;
  gap: 80px;
}

.section2 .progress:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.486);
  opacity: 0;
  transition: 0.2s linear 1.9s;
}

.section2 .progress .container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 130px;
  bottom: 0;
  border-radius: 10%;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.219);
  border: 2px solid hsla(0, 0%, 100%, 0.719);
  cursor: pointer;
  opacity: 0;
  transform: translateY(100px);
  transition: 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.section2 .progress .container h2 {
  font-family: "Varela Round", sans-serif;
  font-size: 42px;
  color: transparent;
  -webkit-text-stroke: 1px var(--clr-pri);
}

.section2 .progress .container span {
  position: relative;
  max-width: 90%;
  font-family: "Varela Round", sans-serif;
  font-size: 17px;
  text-align: center;
  color: var(--clr-pri);
}

.section2.active .progress .container {
  animation: show 1s ease forwards;
  animation-delay: calc(var(--x) * 0.4s);
}

.section2.active .progress::before {
  opacity: 1;
}

.section2 .progress .container:hover {
  border: 2px solid var(--clr-pri);
  bottom: 15px;
}

@media (max-width: 700px) {
  .section2 {
    gap: 20px;
    width: 100vw;
    padding: 0 5px;
  }

  .section2 .text-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .section2 .text-content h1 {
    font-size: 16px;
  }

  .section2 .text-content h1 span {
    font-size: 20px;
  }

  @keyframes animate-exp {
    to {
      height: 35px;
    }
  }

  .section2 .text-content .text1 h2 {
    font-size: 16px;
    margin-top: 0;
  }

  .section2 .text-content .text2 h2 {
    font-size: 16px;
    margin-top: 0;
  }

  @keyframes animate-started {
    to {
      height: 30px;
    }
  }

  .section2 .text-content .text {
    max-width: 100%;
  }

  .section2 .progress {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    align-self: center;
    margin-top: 30px;
    gap: 40px;
  }

  .section2 .progress .container {
    height: 80px;
    width: 80px;
    gap: 5px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    opacity: 0;
    transform: translateY(100px);
    transition: 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .section2 .progress .container span {
    font-size: 15px;
    max-width: 100%;
  }
}
